import { hexToRgbA } from "app/lib/color";
import tw, { css, theme } from "twin.macro";

const green = theme<string>("colors.primary70");

const dayColor = hexToRgbA(theme<string>("colors.black"), 0.6);
const darkModeDayColor = hexToRgbA(theme<string>("colors.white"), 0.6);

const pickerGlobalStyles = css`
  :root {
    --litepicker-container-months-color-bg: transparent;
    --litepicker-container-months-box-shadow-color: transparent;
    /* --litepicker-footer-color-bg: #fafafa; */
    /* --litepicker-footer-box-shadow-color: #ddd; */
    --litepicker-tooltip-color-bg: #fff;
    --litepicker-month-header-color: inherit;
    --litepicker-button-prev-month-color: inherit;
    --litepicker-button-next-month-color: inherit;
    --litepicker-button-prev-month-color-hover: transparent;
    --litepicker-button-next-month-color-hover: transparent;
    --litepicker-month-width: calc(var(--litepicker-day-width) * 7); // 7 days
    --litepicker-month-weekday-color: inherit;
    --litepicker-month-week-number-color: inherit;
    --litepicker-day-width: 40px;
    --litepicker-day-color: ${dayColor};
    --litepicker-day-color-hover: inherit;
    --litepicker-is-today-color: ${green};
    --litepicker-is-in-range-color: ${theme<string>("colors.grey50")};
    /* --litepicker-is-locked-color: #9e9e9e; */
    --litepicker-is-start-color: #fff;
    /* hack to use before as background */
    --litepicker-is-start-color-bg: ${theme<string>("colors.grey50")};
    --litepicker-is-end-color: #fff;
    --litepicker-is-end-color-bg: ${theme<string>("colors.grey50")};
    /* --litepicker-button-cancel-color: #fff; */
    /* --litepicker-button-cancel-color-bg: #9e9e9e; */
    /* --litepicker-button-apply-color: #fff; */
    /* --litepicker-button-apply-color-bg: #2196f3; */
    /* --litepicker-button-reset-color: #909090; */
    /* --litepicker-button-reset-color-hover: #2196f3; */
    /* --litepicker-highlighted-day-color: #333; */
    /* --litepicker-highlighted-day-color-bg: #ffeb3b; */
  }

  .dark {
    :root {
      --litepicker-tooltip-color-bg: #000;
      --litepicker-day-color: ${darkModeDayColor};
      --litepicker-is-in-range-color: #000;

      --litepicker-is-start-color-bg: #000;
      --litepicker-is-end-color-bg: #000;
    }
  }

  .litepicker {
    ${tw`text-base`};
    font-family: inherit;

    .container__tooltip {
      ${tw`z-10`};

      &::before {
        ${tw`dark:border-t-white/[0.12]`};
      }
      &::after {
        ${tw`dark:border-t-black`};
      }
    }

    .container__months {
      &.columns-2 {
        /* 2 calendars, 96px for padding, 1px for border */
        width: calc((var(--litepicker-month-width) * 2) + 96px + 1px);

        .month-item {
          ${tw`px-[24px] py-[20px]`};

          &:first-of-type {
            ${tw`border-r border-r-grey200 dark:border-r-white30`};
          }
        }
      }

      .month-item-header {
        ${tw`h-[36px] py-0`};

        .button-next-month,
        .button-previous-month {
          svg {
            ${tw`w-[24px] h-[24px] fill-none text-black32 dark:text-white32`};

            path {
              ${tw`stroke-current`};
            }
          }
        }

        .month-item-name,
        .month-item-year {
          ${tw`text-[1.6rem] font-semibold`};
        }
      }

      .month-item-weekdays-row {
        > div {
          ${tw`h-[40px] flex items-center justify-center text-base`};
        }
      }

      .container__days {
        .day-item {
          ${tw`relative z-[1] h-[40px] flex items-center justify-center cursor-pointer`};

          &.is-end-date,
          &.is-start-date {
            ${tw`rounded-none`};

            &::after {
              ${tw`content-[''] block rounded-full w-[var(--litepicker-day-width)] h-[40px] absolute top-0 -z-10 bg-primary70`};
            }
          }

          &.is-start-date {
            ${tw`rounded-l-full`};
          }

          &.is-end-date {
            ${tw`rounded-r-full`};
          }
        }
      }
    }

    &[data-plugins*="ranges"]
      > .container__main
      > .container__predefined-ranges {
      ${tw`shadow-none rounded-r-none w-[192px] px-[16px] py-[12px] flex flex-col gap-[4px] border-r border-r-grey200`};

      ${tw`dark:border-r-white30`};

      > button {
        ${tw`h-[40px] rounded-[6px] px-[16px] py-0 flex items-center cursor-pointer hover:opacity-100 hover:bg-greyScale10`};

        ${tw`dark:hover:bg-black`};
      }
    }
  }
`;

export default pickerGlobalStyles;
