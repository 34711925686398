import SelectOption from "app/models/selectOption";

const timeIntervals: SelectOption[] = [
  { label: "Hourly", value: "hour" },
  { label: "Daily", value: "day" },
  { label: "Weekly", value: "week" },
  { label: "Monthly", value: "month" },
];

export default timeIntervals;
