import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Loader from "app/components/Loader";
import logout from "app/lib/logout";
import { useSetToken } from "app/providers/TokenProvider";
import { HOME_PATH } from "app/constants/variables";

export default function Signout() {
  const setToken = useSetToken();

  const location = useLocation();

  useEffect(() => {
    setToken({});

    logout(location.state ? location.state.from || HOME_PATH : HOME_PATH);
  }, [setToken, location.state]);

  return (
    <div tw="min-h-screen flex items-center justify-center">
      <Loader />
    </div>
  );
}
