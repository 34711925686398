import tw, { css } from "twin.macro";

import { FONT_BASE_URL } from "app/constants/variables";

const GlobalStyles = css`
  @font-face {
    font-family: "Roobert";
    src: url("${FONT_BASE_URL}/Roobert-Light.otf");
    font-weight: 300;
  }

  @font-face {
    font-family: "Roobert";
    src: url("${FONT_BASE_URL}/Roobert-Regular.otf");
    font-weight: normal;
  }

  @font-face {
    font-family: "Roobert";
    src: url("${FONT_BASE_URL}/Roobert-Medium.otf");
    font-weight: 500;
  }

  @font-face {
    font-family: "Roobert";
    src: url("${FONT_BASE_URL}/Roobert-SemiBold.otf");
    font-weight: 600;
  }

  @font-face {
    font-family: "Roobert";
    src: url("${FONT_BASE_URL}/Roobert-Bold.otf");
    font-weight: bold;
  }

  @font-face {
    font-family: "Roobert";
    src: url("${FONT_BASE_URL}/Roobert-Heavy.otf");
    font-weight: 900;
  }

  * {
    margin: 0;
    padding: 0px;
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
  }

  body,
  button {
    margin: 0;
    font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    ${tw`text-black95 dark:text-white95`};

    ${tw`bg-greyScale05 dark:bg-black`};
  }

  button {
    cursor: pointer;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .apexcharts-tooltip {
    background-color: transparent;
    border: none;
  }
`;

export default GlobalStyles;
