import tw, { styled } from "twin.macro";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Success } from "../assets/icons/success.svg";
import { ReactComponent as Error } from "../assets/icons/error.svg";

import Card from "./Card";
import Text from "app/styles/Text";
import Button from "./Button";

type Action = {
  label: string;
  url?: string;
  onAction?: VoidFunction;
};

interface Props {
  status: "success" | "error";
  description: string;
  primaryAction: Action;
  className?: string;
}

function StatusView(props: React.PropsWithChildren<Props>) {
  const { status, description, primaryAction, className } = props;

  const Navigate = useNavigate();

  return (
    <Card className={className}>
      <Container>
        <div className="main">
          {status === "success" && <Success />}
          {status === "error" && <Error />}

          <Text big>{description}</Text>

          {props.children}
        </div>

        {primaryAction.url ? (
          <Button
            primary
            fullWidth
            onClick={() => Navigate(`${primaryAction.url}`)}
          >
            {primaryAction.label}
          </Button>
        ) : (
          <Button primary fullWidth onClick={primaryAction.onAction}>
            {primaryAction.label}
          </Button>
        )}
      </Container>
    </Card>
  );
}

const Container = styled.div`
  padding: 50px 20px 20px 20px;

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid rgba(91, 97, 110, 0.2);
    padding-bottom: 20px;

    svg {
      margin-bottom: 20px;

      ${tw` dark:fill-current text-white`}
    }

    > p {
      margin-bottom: 10px;
      text-align: center;
      width: 375px;
      max-width: 100%;
    }
  }

  > button {
    margin-top: 15px;
  }
`;

export default StatusView;
