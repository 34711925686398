import { GetDepositReconcialiationResponse } from "app/api/deposits/types";
import Card from "app/components/Card";
import LineChart, { ChartDataItem } from "app/components/Charts/LineChart";
import { formatMoney } from "app/lib/money";
import Header from "app/styles/Header";
import Text from "app/styles/Text";

interface Props {
  data: GetDepositReconcialiationResponse["data"];
}
export default function DepositReconciliation(props: Props) {
  const {
    data: { reconciliation_rate, unit },
  } = props;

  let datasets: ChartDataItem[] = [];

  const totalVolume = reconciliation_rate.reduce((prev, curr) => {
    datasets.push({
      x: new Date(curr.date),
      y: Number(curr.reconciliation_rate),
    });
    return prev + Number(curr.reconciliation_rate);
  }, 0);

  const currency = reconciliation_rate[0]?.currency ?? "";

  return (
    <Card blackOnDark tw="p-[20px]">
      <Text subdued>Deposit reconciliation rate</Text>

      <Header tw="font-normal mb-[24px]">
        {formatMoney(totalVolume, "digital", currency)}
      </Header>

      <LineChart
        datasets={[{ data: datasets, borderColor: "#0C75AF" }]}
        currency={currency}
        unit={unit}
      />
    </Card>
  );
}
