import { GetDepositVolumeResponse } from "app/api/deposits/types";
import Card from "app/components/Card";
import LineChart, { ChartDataItem } from "app/components/Charts/LineChart";
import { formatMoney } from "app/lib/money";
import Header from "app/styles/Header";
import Text from "app/styles/Text";

interface Props {
  data: GetDepositVolumeResponse["data"];
}
export default function DepositVolume(props: Props) {
  const {
    data: { volume: volumes, unit },
  } = props;

  let datasets: ChartDataItem[] = [];

  const totalVolume = volumes.reduce((prev, curr) => {
    datasets.push({
      x: new Date(curr.date),
      y: Number(curr.deposit_volume),
    });
    return prev + Number(curr.deposit_volume);
  }, 0);

  const currency = volumes[0]?.currency ?? "";

  return (
    <Card blackOnDark tw="p-[20px]">
      <Text subdued>Deposit Volume</Text>

      <Header tw="font-normal mb-[24px]">
        {formatMoney(totalVolume, "digital", currency)}
      </Header>

      <LineChart
        datasets={[{ data: datasets, borderColor: "#A92648" }]}
        currency={currency}
        unit={unit}
      />
    </Card>
  );
}
