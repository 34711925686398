import { ForgotPasswordResponse } from "app/api/auth/types";
import AuthPageContainer from "app/components/AuthPageContainer";
import AuthStepIndicator from "app/components/AuthStepIndicator";
import StatusView from "app/components/StatusView";
import { LOGIN_PATH } from "app/constants/variables";
import { useMemo, useState } from "react";
import EmailForm from "./components/EmailForm";
import PasswordForm from "./components/PasswordForm";

type ForgotPasswordStep = "email" | "password" | "success";

export default function ForgotPassword() {
  const [activeStep, setActiveStep] = useState<ForgotPasswordStep>("email");
  const stepIndex = useMemo(() => {
    switch (activeStep) {
      case "email":
        return 1;
      case "password":
        return 2;
    }
    return 2;
  }, [activeStep]);

  const [forgotPasswordData, setForgotPasswordData] =
    useState<ForgotPasswordResponse>();

  return (
    <AuthPageContainer
      secondaryTitle={
        activeStep !== "success" ? (
          <AuthStepIndicator steps={2} activeStep={stepIndex} />
        ) : undefined
      }
    >
      {activeStep === "email" && (
        <EmailForm
          onSuccess={(d) => {
            setForgotPasswordData(d);
            setActiveStep("password");
          }}
        />
      )}

      {activeStep === "password" && forgotPasswordData && (
        <PasswordForm
          forgotPasswordData={forgotPasswordData}
          onSuccess={() => {
            setActiveStep("success");
          }}
        />
      )}

      {activeStep === "success" && (
        <StatusView
          tw="!bg-transparent !border-none"
          status="success"
          description="Password reset successful"
          primaryAction={{
            label: "Login",
            url: `/${LOGIN_PATH}`,
          }}
        />
      )}
    </AuthPageContainer>
  );
}
