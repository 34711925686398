import tw, { styled } from "twin.macro";
import Button from "./Button";

const DropdownButton = styled(Button)`
  ${tw`h-[36px] text-inherit border capitalize border-black12 dark:border-white12 dark:bg-greyScale90`};

  img {
    ${tw`w-[16px] h-[16px] rounded-full flex-shrink-0`};
  }

  svg {
    /* ${tw`text-black dark:text-white95`}; */

    &.dropdown {
      ${tw`-rotate-90`};
    }

    path {
      ${tw`fill-current`};
    }
  }
`;

export default DropdownButton;
