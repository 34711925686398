import { COIN_IMG_BASE_URL } from "app/constants/variables";
import Currency from "app/models/currency";
import SelectOption from "app/models/selectOption";

export const getCurrencySelectOptions = (
  currencies: Currency[]
): SelectOption[] => {
  return currencies.map((c) => ({
    label: c.code,
    value: c.code,
    icon: `${COIN_IMG_BASE_URL}/${c.code}.svg`,
  }));
};
