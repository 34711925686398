import { PropsWithChildren, ReactNode } from "react";
import tw, { styled } from "twin.macro";

import { useAppTheme } from "app/providers/AppThemeProvider";
import { ReactComponent as LogoDark } from "app/assets/images/logo-dark.svg";
import { ReactComponent as LogoWhite } from "app/assets/images/logo-white.svg";

interface Props {
  secondaryTitle?: ReactNode;
}
export default function AuthPageContainer(props: PropsWithChildren<Props>) {
  const { children, secondaryTitle } = props;

  const theme = useAppTheme();
  return (
    <Container>
      <div className="header">
        {theme === "dark" ? (
          <LogoWhite className="logo" />
        ) : (
          <LogoDark className="logo" />
        )}

        {secondaryTitle}
      </div>

      {children}
    </Container>
  );
}

const Container = styled.main`
  ${tw`w-[552px] max-w-full mx-auto pt-[156px] mobile:px-[16px]`}

  > .header {
    ${tw`flex items-center justify-between mb-[48px]`};

    > .logo {
      ${tw`w-[108.65px] h-[28px]`};
    }
  }
`;
