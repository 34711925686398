import tw, { styled } from "twin.macro";

import Text from "app/styles/Text";

import { ReactComponent as MenuIcon } from "app/assets/icons/menu.svg";
import { useProfile } from "app/providers/ProfileProvider";
import Profile from "app/models/profile";
import SubHeader from "app/styles/SubHeader";

function getFullName(p: Profile) {
  return `${p.first_name ?? "Oluwatobi"} ${p.last_name ?? "Akindunjoye"}`;
}

function getInitials(p: Profile) {
  return `${p?.first_name?.substring(0, 1) ?? "O"}${
    p?.last_name?.substring(0, 1) ?? "A"
  }`;
}

export default function ProfileDropdown() {
  const profile = useProfile();

  if (!profile) return null;

  const fullname = getFullName(profile);
  const initials = getInitials(profile);

  return (
    <Container type="button">
      <div className="name__icon">
        <SubHeader tw="text-primary70 font-medium">{initials}</SubHeader>
      </div>

      <Text mediumBold>{fullname}</Text>

      <MenuIcon className="mobile-menu" />
    </Container>
  );
}

const Container = styled.button`
  ${tw`flex items-center cursor-pointer`};

  img {
    ${tw`w-[36px] h-[36px] mr-[8px] rounded-full`};
  }

  img,
  p {
    ${tw`mobile:hidden`};
  }

  .name__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
    ${tw`bg-primary70/[0.1]`};
  }

  svg.mobile-menu {
    ${tw`w-[20px] h-[16px] hidden mobile:block`};

    path {
      ${tw`dark:fill-[white]`};
    }
  }
`;
