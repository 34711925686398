import request from "../request";
import { BaseResponse } from "../types";
import {
  ForgotPasswordPayload,
  ForgotPasswordResponse,
  LoginConfirmPayload,
  LoginPayload,
  LoginResponse,
  ResetPasswordPayload,
} from "./types";

const defaultOption = {
  headers: {
    noToken: true,
  },
};

export const verifyEmail = (email: string) => {
  return request.get(`verify/email/${email}`, defaultOption);
};

export const verifyReferralCode = (code: string) => {
  return request.post<{ valid: boolean }>(
    "verify/referral_code",
    { code },
    defaultOption
  );
};

export const verifyUsername = (username: string) => {
  return request.post<{ valid: boolean }>(
    "verify/username",
    { username },
    defaultOption
  );
};

export const login = (data: LoginPayload) => {
  return request.post<LoginResponse>("auth/login", data, defaultOption);
};

export const loginConfirm = async (payload: LoginConfirmPayload) => {
  const { code, token } = payload;

  return request.post<LoginResponse>(
    "auth/confirm",
    { code },
    {
      ...defaultOption,
      headers: {
        Authorization: `Bearer ${token}`,
        noToken: true,
      },
    }
  );
};

export const resendAuthCode = async (token: string) => {
  return request.get<BaseResponse>("auth/resend/code", {
    ...defaultOption,
    headers: {
      Authorization: `Bearer ${token}`,
      noToken: true,
    },
  });
};

export const forgotPassword = (data: ForgotPasswordPayload) => {
  return request.post<ForgotPasswordResponse>(
    "password/forgot",
    data,
    defaultOption
  );
};

export const resetPassword = ({
  forgotPasswordToken,
  ...data
}: ResetPasswordPayload) => {
  return request.put<BaseResponse>("password/reset", data, {
    ...defaultOption,
    headers: {
      Authorization: `Bearer ${forgotPasswordToken}`,
      noToken: true,
    },
  });
};
