import clsx from "clsx";
import tw, { styled } from "twin.macro";

import { ReactComponent as Bad } from "app/assets/icons/close.svg";
import { ReactComponent as Good } from "app/assets/icons/check-outlined.svg";
import Text from "app/styles/Text";

interface Props {
  matches: boolean;
  condition: { name: string; regex: RegExp };
}
export default function PasswordCondition({ condition, matches }: Props) {
  return (
    <PasswordConditionBox key={condition.name}>
      {matches ? <Good className="good" /> : <Bad className="bad" />}

      <Text className={clsx({ good: matches })}>{condition.name}</Text>
    </PasswordConditionBox>
  );
}

const PasswordConditionBox = styled.div`
  ${tw`mt-[4px] flex items-center`};

  > p {
    ${tw`text-error70 dark:text-error50`};

    &.good {
      ${tw`text-primary70`};
    }
  }

  > svg {
    ${tw`w-[24px] h-[24px] mr-[4px]`};

    path {
      ${tw`fill-current`};
    }

    &.bad {
      ${tw`text-error70 dark:text-error50`}
    }

    &.good {
      ${tw`text-primary70`}
    }
  }
`;
