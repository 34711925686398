import { NavLink, useLocation } from "react-router-dom";
import tw, { styled, theme } from "twin.macro";
import clsx from "clsx";

import appLinks from "app/constants/appLinks";
import { useAppTheme } from "app/providers/AppThemeProvider";

export default function DashboardMobilenav() {
  const theme = useAppTheme();

  const location = useLocation();

  return (
    <Container>
      {appLinks.map(({ label, icon: Icon, url, children }) => {
        const subNavActive = (children ?? [])
          .map((r) => r.url)
          .includes(location.pathname);

        return (
          <Link key={label} to={url} className={clsx({ subNavActive })}>
            <Icon className={theme} /> {label}
          </Link>
        );
      })}
    </Container>
  );
}

const Container = styled.nav`
  ${tw`w-full h-full flex border-t px-[8px]`};

  ${tw`bg-white `};

  ${tw`dark:bg-black dark:border-t-white12`};
`;

const Link = styled(NavLink)`
  ${tw`w-[89.75px] h-full flex-shrink-0 flex-grow flex flex-col items-center justify-center text-base text-black32`};

  ${tw`dark:text-white32`};

  svg {
    ${tw`w-[24px] h-[24px] mb-[4px]`};

    &.dark {
      path {
        fill: ${theme<string>`colors.white32`};
        fill-opacity: 1;
      }
    }
  }

  &.active,
  &.subNavActive {
    ${tw`text-black`};

    ${tw`dark:text-white`};

    svg {
      path {
        fill: black;
        fill-opacity: 1;
      }

      &.dark {
        path {
          fill: white;
          fill-opacity: 1;
        }
      }
    }
  }
`;
