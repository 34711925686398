import { useQuery } from "@tanstack/react-query";
import { getDepositVolume } from "app/api/deposits";
import { GetDepositVolumePayload } from "app/api/deposits/types";
import parseError from "app/lib/parseError";
import { AxiosError } from "axios";

export default function useGetDepositVolume(params: GetDepositVolumePayload) {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["deposit-volume", params],
    () => getDepositVolume(params)
  );

  return {
    volumes: data?.data.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getDepositVolume: refetch,
    isFetching,
    isLoading,
  };
}
