import * as React from "react";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";

import { isString } from "app/utils/helpers";
import SubHeader from "../styles/SubHeader";
import Text from "../styles/Text";

interface CardAction {
  label?: string;
  url?: string;
  onAction?: () => void;
}
interface Props
  extends Omit<React.BaseHTMLAttributes<HTMLDivElement>, "title"> {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  primaryAction?: CardAction;
}

interface CardElProps {
  blackOnDark?: boolean;
}

function Card(props: React.PropsWithChildren<Props & CardElProps>) {
  const { title, subTitle, primaryAction, ...rest } = props;

  const showHeader = Boolean(title || subTitle);

  return (
    <CardContainer {...rest}>
      {showHeader ? (
        <div className="header">
          <div>
            {!!title ? (
              isString(title) ? (
                <SubHeader big>{title}</SubHeader>
              ) : (
                title
              )
            ) : null}

            {!!subTitle ? (
              isString(subTitle) ? (
                <Text>{subTitle}</Text>
              ) : (
                subTitle
              )
            ) : null}
          </div>

          {primaryAction ? (
            primaryAction.url ? (
              <ActionLink to={primaryAction.url}>
                {primaryAction.label}
              </ActionLink>
            ) : (
              <ActionButton onClick={primaryAction.onAction}>
                {primaryAction.label}
              </ActionButton>
            )
          ) : null}
        </div>
      ) : null}

      {props.children}
    </CardContainer>
  );
}

const CardContainer = styled.div<CardElProps>`
  background-color: #fff;
  ${tw`dark:bg-[#0D0F12]`};
  border: 1px solid rgba(91, 97, 110, 0.2);
  border-radius: 8px;

  ${(props) =>
    props.blackOnDark &&
    css`
      ${tw`dark:bg-black`};
    `};

  .header {
    display: flex;
    padding: 10px 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(91, 97, 110, 0.2);

    p {
      ${tw`text-black60 dark:text-white60`}
    }
  }
`;

const actionStyles = css`
  color: #12a633;
  font-size: 1.4rem;
  font-weight: 600;
`;

const ActionLink = styled(Link)`
  ${actionStyles};
`;

const ActionButton = styled.button`
  ${actionStyles};
`;

export default Card;
