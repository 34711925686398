import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import {
  useCurrencies,
  useSetCurrencies,
} from "app/providers/CurrenciesProvider";
import { getCurrencies } from "app/api/currencies";

const useGetCurrencies = (enabled = true, opt = { useCache: true }) => {
  const currencies = useCurrencies();

  const setCurrencies = useSetCurrencies();

  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    ["currencies"],
    getCurrencies,
    {
      enabled: opt.useCache ? enabled && !currencies : enabled,
      onSuccess: (data) => {
        setCurrencies(data.data);
      },
    }
  );

  return {
    currencies: data?.data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
    isFetching,
    isLoading,
  };
};

export default useGetCurrencies;
