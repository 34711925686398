import request from "../request";
import {
  GetAverageDepositResponse,
  GetDepositFulfilledResponse,
  GetDepositRecievedResponse,
  GetDepositReconcialiationResponse,
  GetDepositVolumePayload,
  GetDepositVolumeResponse,
} from "./types";

export const getDepositVolume = ({
  currency,
  ...params
}: GetDepositVolumePayload) => {
  return request.get<GetDepositVolumeResponse>(`/deposits/${currency}/volume`, {
    params,
  });
};

export const getAverageDeposit = ({
  currency,
  ...params
}: GetDepositVolumePayload) => {
  return request.get<GetAverageDepositResponse>(
    `/deposits/${currency}/average_volume`,
    {
      params,
    }
  );
};

export const getDepositReconcialiation = ({
  currency,
  ...params
}: GetDepositVolumePayload) => {
  return request.get<GetDepositReconcialiationResponse>(
    `/deposits/${currency}/reconciliation_rate`,
    {
      params,
    }
  );
};

export const getDepositFulfilled = ({
  currency,
  ...params
}: GetDepositVolumePayload) => {
  return request.get<GetDepositFulfilledResponse>(
    `/deposits/${currency}/fulfilled`,
    {
      params,
    }
  );
};

export const getDepositRecieved = ({
  currency,
  ...params
}: GetDepositVolumePayload) => {
  return request.get<GetDepositRecievedResponse>(
    `/deposits/${currency}/received`,
    {
      params,
    }
  );
};
