import * as React from "react";

import Currency from "app/models/currency";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import storage from "app/lib/storage";
import { CURRENCIES_STORAGE_KEY } from "app/constants/variables";

export const CurrenciesContext = React.createContext<Currency[] | undefined>(
  undefined
);
CurrenciesContext.displayName = "CurrenciesContext";

export const SetCurrenciesContext = React.createContext<
  React.Dispatch<React.SetStateAction<Currency[] | undefined>>
>((p) => {});
SetCurrenciesContext.displayName = "SetCurrenciesContext";

export default function CurrenciesProvider(props: React.PropsWithChildren<{}>) {
  const [currencies, setCurrencies] = React.useState<Currency[] | undefined>(
    () => storage.get(CURRENCIES_STORAGE_KEY) ?? undefined
  );

  useUpdatedEffect(() => {
    if (currencies) {
      storage.set(CURRENCIES_STORAGE_KEY, currencies);
    }
  }, [currencies]);

  return (
    <CurrenciesContext.Provider value={currencies}>
      <SetCurrenciesContext.Provider value={setCurrencies}>
        {props.children}
      </SetCurrenciesContext.Provider>
    </CurrenciesContext.Provider>
  );
}

export const useCurrencies = () => {
  const currencies = React.useContext(CurrenciesContext);

  return currencies;
};

export const useSetCurrencies = () => {
  const setCurrencies = React.useContext(SetCurrenciesContext);

  return setCurrencies;
};
