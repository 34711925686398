import axios, { AxiosError } from "axios";

import storage from "app/lib/storage";
import {
  API_URL,
  SIGN_OUT_PATH,
  TOKEN_STORAGE_KEY,
  UNAUTHORIZED_ERROR_CODE,
} from "app/constants/variables";
import history from "app/lib/history";

const request = axios.create({
  baseURL: API_URL,
});

request.interceptors.request.use((config) => {
  const { access_token } = storage.get(TOKEN_STORAGE_KEY) ?? {};

  const newConfig = {
    ...config,
  };

  const requiresNoToken = newConfig.headers["noToken"] as boolean;

  delete newConfig.headers["noToken"];

  if (!access_token || requiresNoToken) return newConfig;

  newConfig.headers.Authorization = `Bearer ${access_token}`;

  return newConfig;
});

// Add a response interceptor
request.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError) {
    const { access_token } = storage.get("tokens") || {};

    // if user's token has expired or has been blacklisted
    if (error.response?.status === UNAUTHORIZED_ERROR_CODE && access_token) {
      // log user out
      // logout({
      //   from: window.location.pathname,
      // });
      history.push(`/${SIGN_OUT_PATH}`, { from: window.location.pathname });
    }
    return Promise.reject(error);
  }
);

export default request;
