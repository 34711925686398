import NavigationItem from "app/models/navigationItem";

// import { ReactComponent as Explore } from "app/assets/icons/explore-outline.svg";
import { ReactComponent as Payout } from "app/assets/icons/withdraw.svg";
import { ReactComponent as Deposit } from "app/assets/icons/deposit.svg";
// import { ReactComponent as Settings } from "app/assets/icons/settings.svg";

const appLinks: NavigationItem[] = [
  // {
  //   label: "Balances",
  //   url: "/",
  //   icon: Explore,
  // },
  {
    label: "Payout",
    url: "/payout",
    icon: Payout,
  },
  {
    label: "Deposits",
    url: "/deposits",
    icon: Deposit,
  },
  // {
  //   label: "Settings",
  //   url: "/settings",
  //   icon: Settings,
  // },
];

export default appLinks;
