import { RouteObject } from "react-router-dom";

import { HOME_PATH, LOGIN_PATH, SIGN_OUT_PATH } from "app/constants/variables";

import ProfileProvider from "app/providers/ProfileProvider";
import DashboardLayout from "app/components/DashboardLayout";
import DashboardHeader from "app/components/DashboardHeader";
import DashboardSideNav from "app/components/DashboardSideNav";
import Header from "app/styles/Header";
import DashboardMobileNav from "app/components/DashboardMobileNav";
import Login from "app/views/Login";
import Signout from "app/views/Signout";
import ForgotPassword from "app/views/ForgotPassword";
import Balances from "app/views/Balances";
import Deposits from "app/views/Deposits";
import Demo from "app/views/Demo";
import CurrenciesProvider from "app/providers/CurrenciesProvider";
// import AuthRoutesWrapper from "app/components/AuthRoutesWrapper";

const routesObject: RouteObject[] = [
  {
    path: `/${SIGN_OUT_PATH}`,
    element: <Signout />,
  },
  {
    path: `/${LOGIN_PATH}`,
    element: <Login />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    element: (
      <ProfileProvider>
        <CurrenciesProvider>
          {/* <AuthRoutesWrapper> */}
          <DashboardLayout
            header={<DashboardHeader />}
            sidenav={<DashboardSideNav />}
            mobilenav={<DashboardMobileNav />}
          />
          {/* </AuthRoutesWrapper> */}
        </CurrenciesProvider>
      </ProfileProvider>
    ),
    children: [
      {
        path: HOME_PATH,
        element: <Balances />,
      },
      {
        path: "profile",
        element: <Header>Profile</Header>,
      },
      {
        path: "payout",
        element: <Header>Payouts</Header>,
      },
      {
        path: "deposits",
        element: <Deposits />,
      },
      {
        path: "settings",
        element: <Header>Settings</Header>,
      },
      process.env.NODE_ENV === "development"
        ? {
            path: "demo",
            element: <Demo />,
          }
        : undefined,
      {
        path: "*",
        element: <Header> 404 👀</Header>,
      },
    ].filter(Boolean) as RouteObject[],
  },
];

export default routesObject;
