import { useRef, useState } from "react";
import isEqual from "lodash.isequal";
import {
  Chart as ChartJS,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  LineOptions,
  Filler,
  ChartDataset,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import tw, { styled, theme } from "twin.macro";

import { getRGBFromString, hexToRgbA, rgbObjectToRGBA } from "app/lib/color";
import Text from "app/styles/Text";
import { useAppTheme } from "app/providers/AppThemeProvider";
import { formatDateStr } from "app/utils/helpers";

ChartJS.register(
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip
);

export interface ChartDataItem {
  x: Date;
  y: number | null;
}

interface ChartTooltipData extends ChartDataItem {
  borderColor: string;
}

interface Props {
  currency?: string;
  options?: LineOptions;
  datasets: ChartDataset<"line", ChartDataItem[]>[];
  unit?: string;
}

// const data: ChartData<"line", ChartDataItem[]> = {
//   //   name: "Line Chart",
//   datasets: [],
// };

type ChartToolTip = {
  top: string;
  left: string;
  opacity: string;
  data?: ChartTooltipData[];
};

export default function LineChart(props: Props) {
  const { options, currency, datasets, unit = "day" } = props;

  const defaultColor = theme<string>("colors.primary70");

  const appTheme = useAppTheme();

  const textColor =
    appTheme !== "dark"
      ? theme<string>("colors.black60")
      : theme<string>("colors.white60");

  const chartRef = useRef();

  const [chartTooltip, setChartTooltip] = useState<ChartToolTip>({
    top: "0px",
    left: "0px",
    opacity: "0",
    data: undefined,
  });

  return (
    <Container>
      <Line
        ref={chartRef}
        data={{ datasets }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          borderColor: defaultColor,
          font: {
            family: "Roobert",
          },
          elements: {
            line: {
              borderCapStyle: "round",
              borderWidth: 2,
              fill: true,
              backgroundColor: (ctx, opts) => {
                const baseColor =
                  (ctx.dataset.borderColor as string) ?? defaultColor;

                const opacity = 0.05;

                if (baseColor.startsWith("#")) {
                  const rgba = hexToRgbA(baseColor, opacity);

                  return rgba ?? undefined;
                }

                if (baseColor.startsWith("rgb(")) {
                  const rgb = getRGBFromString(baseColor);

                  const rgba = rgbObjectToRGBA(rgb, opacity);

                  return rgba;
                }

                return undefined;
              },
            },
            point: {
              radius: 0,
              hitRadius: 4,
              hoverRadius: 4,
              hoverBackgroundColor: "white",
              hoverBorderWidth: 2,
            },
          },
          // hover: {},
          // interaction: {
          //   mode: "point",
          //   includeInvisible: true,
          // },
          scales: {
            x: {
              type: "time",
              alignToPixels: true,
              time: {
                unit: unit as any,
                round: unit as any,
              },
              ticks: {
                color: textColor,
                maxRotation: 0,
                align: "inner",
                callback(tickValue, index, ticks) {
                  // console.log({ tickValue, index, ticks });
                  return index === 0 || index === ticks.length - 1
                    ? formatDateStr(
                        tickValue,
                        unit === "hour" ? "HH:mm" : "D MMM"
                      )
                    : "";
                },
              },
              grid: {
                color: "#9DA4AE",
                tickLength: 0,
              },
              border: {
                dash: [4, 8],
              },
            },
            y: {
              display: false,
              beginAtZero: true,
            },
          },
          plugins: {
            tooltip: {
              enabled: false,
              external({ chart, tooltip }) {
                if (!chartRef || !chartRef.current) return;

                // Hide if no tooltip
                if (tooltip.opacity === 0) {
                  const tooltipData = { ...chartTooltip, opacity: "0" };

                  if (!isEqual(chartTooltip, tooltipData))
                    setChartTooltip(tooltipData);
                  return;
                }

                const { top, left } = chart.canvas.getBoundingClientRect();

                // console.log(tooltip.dataPoints);

                const tooltipData = {
                  top: top + tooltip.caretY + "px",
                  left: left + tooltip.caretX + "px",
                  opacity: "1",
                  data: tooltip.dataPoints.map((dataPoint) => ({
                    ...(dataPoint.dataset.data[dataPoint.dataIndex] as any),
                    borderColor: dataPoint.dataset.borderColor ?? defaultColor,
                  })),
                };

                if (!isEqual(chartTooltip, tooltipData))
                  setChartTooltip(tooltipData);
              },
            },
          },
          ...options,
        }}
      />

      <div
        className="tooltip"
        style={{
          top: chartTooltip.top,
          left: chartTooltip.left,
          opacity: chartTooltip.opacity,
        }}
      >
        <CustonTooltip
          tooltip={chartTooltip}
          currency={currency ?? ""}
          unit={unit}
        />
      </div>
    </Container>
  );
}

interface CustonTooltipProps {
  tooltip: ChartToolTip;
  currency: string;
  unit?: string;
}

function CustonTooltip({ tooltip, currency, unit }: CustonTooltipProps) {
  if (!tooltip.data) return null;

  const date = tooltip.data[0].x;

  return (
    <TooltipContainer>
      <Text small>
        {formatDateStr(date, `MMM D YYYY${unit === "hour" ? ", HH:mm" : ""}`)}
      </Text>

      <div className="points">
        {tooltip.data.map((point, i) => (
          <Text key={i} small subdued>
            <span style={{ borderColor: point.borderColor }} />

            {`${point.y} ${currency}`}
          </Text>
        ))}
      </div>
    </TooltipContainer>
  );
}

const Container = styled.div`
  ${tw`h-[232px] w-full`};

  .tooltip {
    ${tw`pointer-events-none absolute -translate-x-full -translate-y-1/2 transition-all`};
  }
`;

const TooltipContainer = styled.div`
  ${tw`bg-white rounded-[4px] shadow-[0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)]`};

  ${tw`dark:bg-black`};

  p {
    ${tw`px-[12px] py-[6px] whitespace-nowrap`};
  }

  .points {
    ${tw`border-t border-t-[rgba(0, 0, 0, 0.24)]`};

    ${tw`dark:border-t-[rgba(255, 255, 255, 0.3)]`};

    span {
      ${tw`block w-[10px] h-[10px] border-[2px] rounded-full mr-[6px]`};
    }

    p {
      ${tw`flex items-center`};
    }
  }
`;
