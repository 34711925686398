import { Fragment, useEffect, useRef, useState } from "react";
import Litepicker from "litepicker";
import "litepicker/dist/plugins/ranges";
import { Global } from "@emotion/react";
import tw, { styled } from "twin.macro";
import clsx from "clsx";
import { Popover } from "@headlessui/react";

import datepickerIcons from "app/constants/datepickerIcons";
import DropdownButton from "./DropdownButton";
import Button from "./Button";
import TextField from "./TextField";
import { ReactComponent as DropdownIcon } from "app/assets/icons/chevron-left.svg";
import { ReactComponent as CalendarIcon } from "app/assets/icons/calendar.svg";
import pickerGlobalStyles from "app/styles/dateRangePickerStyles";
import { formatDateStr } from "app/utils/helpers";
import { DEFAULT_DATE_QUERY_FORMAT } from "app/constants/variables";

type LitepickerDate = { dateInstance: Date };

interface Props {
  startDate?: Date | string;
  endDate?: Date | string;
  onChange: (d: { start: string; end: string }) => void;
}
export default function DateRangePicker(props: Props) {
  const { startDate, endDate, onChange } = props;

  const ref = useRef<HTMLInputElement>(null);

  const [date, setDate] = useState(() => ({
    start: formatDate(startDate ?? new Date()),
    end: formatDate(endDate ?? new Date()),
  }));

  useEffect(() => {
    if (!ref.current) return;

    const picker = new Litepicker({
      element: ref.current,
      singleMode: false,
      inlineMode: true,
      numberOfMonths: 2,
      numberOfColumns: 2,
      startDate,
      endDate,
      buttonText: {
        nextMonth: datepickerIcons.right,
        apply: "",
        cancel: "",
        previousMonth: datepickerIcons.left,
        reset: "",
      },
      setup(picker) {
        picker.on("selected", (start: LitepickerDate, end: LitepickerDate) => {
          //   console.log({ start, end });

          setDate({
            start: formatDate(start.dateInstance, DEFAULT_DATE_QUERY_FORMAT),
            end: formatDate(end.dateInstance, DEFAULT_DATE_QUERY_FORMAT),
          });
        });
      },
      plugins: ["ranges"],
    });

    return () => {
      picker.destroy();
    };
  }, [endDate, startDate]);

  const onApply = (onDone: VoidFunction) => () => {
    onChange(date);
    onDone();
  };

  return (
    <>
      <Global styles={pickerGlobalStyles} />

      <Popover tw="relative">
        {({ open, close }) => (
          <>
            <Popover.Button as={Fragment}>
              <DropdownButton
                icon={<CalendarIcon />}
                type="button"
                small
                outline
              >
                {formatDate(startDate ?? new Date())} -{" "}
                {formatDate(endDate ?? new Date())}
                <DropdownIcon className="dropdown" />
              </DropdownButton>
            </Popover.Button>

            <Popover.Panel static as={Dropdown} className={clsx({ open })}>
              <input ref={ref} tw="hidden" />

              <Footer>
                <div>
                  <TextField
                    label="Start date"
                    labelHidden
                    value={formatDate(date.start)}
                    readOnly
                    containerClassName="text-field"
                  />

                  <div className="divider" />

                  <TextField
                    label="End date"
                    labelHidden
                    value={formatDate(date.end)}
                    readOnly
                    containerClassName="text-field"
                  />
                </div>

                <div>
                  <Button type="button" tertiary small onClick={close}>
                    Cancel
                  </Button>

                  <Button type="button" primary small onClick={onApply(close)}>
                    Apply
                  </Button>
                </div>
              </Footer>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  );
}

const Dropdown = styled.div`
  ${tw`absolute top-full mt-[13px] z-10 hidden flex-col`};

  ${tw`min-w-[151px] min-h-[100px] rounded-[8px] border border-transparent bg-white shadow-[0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)]`};

  ${tw`dark:bg-greyScale90 dark:border-white12`};

  &.open {
    ${tw`flex`};
  }

  .litepicker {
    ${tw`order-1`};
  }
`;

const Footer = styled.div`
  ${tw`order-2 p-[16px] border-t border-t-grey200 flex items-center justify-between`};

  ${tw`dark:border-t-white30`};

  .text-field > div {
    ${tw`py-[10px]`};

    input {
      ${tw`text-[1.6rem]`};
    }
  }

  > div {
    ${tw`flex gap-[12px] items-center`};
  }

  .divider {
    ${tw`h-[1px] w-[8px] bg-black32`};

    ${tw`dark:bg-white32`};
  }
`;

function formatDate(date: string | Date, format?: string) {
  return formatDateStr(date, format);
}
