// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

// https://stackoverflow.com/questions/10970958/get-a-color-component-from-an-rgb-string-in-javascript
export function getRGBFromString(rgbString: string) {
  const rgbArray = rgbString.replace(/[^\d,]/g, "").split(",");

  return {
    r: rgbArray[0],
    g: rgbArray[1],
    b: rgbArray[2],
  };
}

export function hexToRgbA(hex: string, opacity: number) {
  const rgb = hexToRgb(hex);
  if (!rgb) return null;

  return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`;
}

export function rgbObjectToRGBA(
  rgb: ReturnType<typeof getRGBFromString>,
  opacity: number
) {
  return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`;
}
