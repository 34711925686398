import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { getProfile } from "app/api/profile";
import parseError from "app/lib/parseError";
import { useSetProfile } from "app/providers/ProfileProvider";

const useGetProfile = (enabled = true) => {
  const setProfile = useSetProfile();

  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    ["profile"],
    getProfile,
    {
      enabled,
      onSuccess: (data) => {
        setProfile(data.data.data);
      },
    }
  );

  return {
    profile: data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
    isFetching,
    isLoading,
  };
};

export default useGetProfile;
