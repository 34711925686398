import { ButtonHTMLAttributes, forwardRef } from "react";
import tw, { styled, css } from "twin.macro";

import media from "app/styles/media";
import Loader from "./Loader";

// type ExtraProps<T extends ElementType> = {
//   as?: T;
// };

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  small?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  outline?: boolean;
  danger?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  responsive?: boolean;
  loading?: boolean;
}

function Button(props: React.PropsWithChildren<Props>, ref: any) {
  const { loading, disabled, ...rest } = props;

  return (
    <ButtonEl ref={ref} disabled={loading || disabled} {...rest}>
      {loading ? (
        <Loader size="20" className="loader" />
      ) : (
        <>
          {!!props.icon && <span>{props.icon}</span>}

          {props.children}
        </>
      )}
    </ButtonEl>
  );
}

const ButtonEl = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 14px 16px;
  border-radius: 4px;
  gap: 8px;

  svg {
    ${tw`w-[16px] h-[16px]`};
  }

  &:disabled {
    opacity: 0.4;
  }

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};

  ${(props) =>
    props.small &&
    css`
      font-size: 14px;
      padding: 8px 16px;
    `};

  ${(props) =>
    props.primary &&
    css`
      ${tw`bg-[#12a633] border border-[#12a633] text-greyScale05 hover:bg-[#0E8127] hover:border hover:border-[#0E8127] dark:text-black95`}

      svg {
        ${tw`dark:fill-current`}
      }

      .loader {
        ${tw`border-t-greyScale05 border-r-greyScale05`};

        ${tw`dark:border-t-black95 dark:border-r-black95`};
      }
    `};

  ${(props) =>
    props.secondary &&
    css`
      ${tw`bg-[#d0f5d8] border border-[#d0f5d8] text-[#0e8127]`}

      .loader {
        ${tw`border-t-[#0e8127] border-r-[#0e8127]`};
      }
    `};

  ${(props) =>
    props.tertiary &&
    css`
      ${tw`bg-greyScale10 border border-greyScale10 text-black95`}

      .loader {
        ${tw`border-t-black95 border-r-black95`};
      }
    `};

  ${(props) =>
    props.outline &&
    css`
      ${tw`text-[#12a633] border border-[#12a633] bg-[#F7F9FA] dark:bg-[#000]`}

      svg {
        ${tw`dark:fill-current`}
      }

      .loader {
        ${tw`border-t-[#12a633] border-r-[#12a633]`};
      }
    `};

  ${(props) =>
    props.danger &&
    css`
      ${tw`bg-[#E94A3F] border border-[#E94A3F] text-greyScale05`}

      .loader {
        ${tw`border-t-greyScale05 border-r-greyScale05`};
      }
    `};

  ${(props) =>
    props.rounded &&
    css`
      ${tw`rounded-[48px]`}
    `};

  ${(props) =>
    props.responsive &&
    css`
      ${media.mobile} {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: #12a633 !important;
        margin-right: 7px;
        padding: 0;
        border: none;
        row-gap: 3px;
        background: none !important;

        :focus {
          background: none;
        }

        span {
          width: 36px;
          height: 36px;
          padding: 10px;
          background-color: #12a633;
          border-radius: 1000px;

          svg {
            fill: #fff !important;
            width: 16px;
            height: 16px;

            ${tw`dark:fill-[#000]!`}
          }
        }
      }
    `};
`;

export default forwardRef(Button);
