import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";

import { ReactComponent as LogoDark } from "app/assets/images/logo-dark.svg";
import { ReactComponent as LogoWhite } from "app/assets/images/logo-white.svg";
import { ReactComponent as Light } from "app/assets/icons/light.svg";
import { ReactComponent as Dark } from "app/assets/icons/dark.svg";

import { useAppTheme, useSetAppTheme } from "app/providers/AppThemeProvider";
import ProfileDropdown from "./ProfileDropdown";
import media from "app/styles/media";
import { DASHBOARD_HEADER_HEIGHT } from "app/constants/variables";

export default function DashboardHeader() {
  const theme = useAppTheme();

  const setTheme = useSetAppTheme();

  const toggleTheme = () => setTheme((t) => (t === "dark" ? "light" : "dark"));

  const logo =
    theme === "dark" ? (
      <LogoWhite className="logo" />
    ) : (
      <LogoDark className="logo" />
    );

  const themeIcon = theme === "dark" ? <Light /> : <Dark />;

  return (
    <Container>
      <Link aria-label="Explore" to="/">
        {logo}
      </Link>

      <div className="secondary">
        <ThemeButton
          aria-label="Change theme"
          type="button"
          onClick={toggleTheme}
        >
          {themeIcon}
        </ThemeButton>

        <ProfileDropdown />
      </div>
    </Container>
  );
}

const Container = styled.nav`
  min-height: ${DASHBOARD_HEADER_HEIGHT}px;
  ${tw`w-full h-full px-[32px] flex items-center justify-between border-b mobile:px-[16px]`};

  ${tw`bg-white `};

  ${tw`dark:bg-black dark:border-b-white12`};

  .logo {
    height: 28px;
    width: 108.65388488769531px;

    ${media.mobile} {
      height: 24px;
      width: 93.13190460205078px;
    }
  }

  .secondary {
    ${tw`flex items-stretch gap-[32px]`};
  }
`;

const ThemeButton = styled.button`
  ${tw`w-[36px] h-[36px] flex items-center justify-center`};

  svg {
    ${tw`w-[20px] h-[20px]`};
  }
`;
