import tw, { styled } from "twin.macro";
import dayjs from "dayjs";

import Page from "app/components/Page";
import DepositFilters from "./components/DepositFilters";
import DepositVolume from "./components/DepositVolume";
import useQueryParams from "app/hooks/useQueryParams";
import { formatDateStr } from "app/utils/helpers";
import { DEFAULT_DATE_QUERY_FORMAT } from "app/constants/variables";
import useGetDepositVolume from "./hooks/useGetDepositVolume";
import LoaderContainer from "app/components/LoaderContainer";
import { DepositQueryParams } from "./types";
import NetworkLoader from "app/components/NetworkLoader";
import useGetAverageDeposit from "./hooks/useGetAverageDeposit";
import useGetDepositReconciliation from "./hooks/useGetDepositReconciliation";
import useGetDepositFulfilled from "./hooks/useGetDepositFulfilled";
import AverageDeposit from "./components/AverageDeposit";
import DepositReconciliation from "./components/DepositReconciliation";
import CustomerDeposits from "./components/CustomerDeposits";
import { useCurrencies } from "app/providers/CurrenciesProvider";
import { getCurrencySelectOptions } from "app/utils/currency";

const lastMonth = dayjs(new Date()).subtract(1, "month").toDate();
const defaultStartDate = formatDateStr(lastMonth, DEFAULT_DATE_QUERY_FORMAT);
const defaultEndDate = formatDateStr(new Date(), DEFAULT_DATE_QUERY_FORMAT);

export default function Deposits() {
  const currencies = getCurrencySelectOptions(useCurrencies() ?? []);

  const {
    queryParams: { currency, startDate, endDate, time_interval },
    updateQueryParams,
  } = useQueryParams<DepositQueryParams>({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    currency: currencies[0]?.value ?? "",
    time_interval: "day",
  });

  const payload = {
    currency: currency ?? currencies[0]?.value ?? "",
    date: `${startDate ?? defaultStartDate}|${endDate ?? defaultEndDate}`,
    time_interval: time_interval ?? "day",
  };

  const {
    error: depositVolError,
    getDepositVolume,
    isFetching: fetchingDepositVol,
    isLoading: loadingDepositVol,
    volumes,
  } = useGetDepositVolume(payload);

  const {
    error: depositAvgError,
    getDepositAverage,
    isFetching: fetchingDepositAvg,
    isLoading: loadingDepositAvg,
    data: depositAvg,
  } = useGetAverageDeposit(payload);

  const {
    error: depositReconError,
    getDepositReconcialiation,
    isFetching: fetchingDepositRecon,
    isLoading: loadingDepositReconData,
    data: depositReconData,
  } = useGetDepositReconciliation(payload);

  const {
    error: depositFulfilledError,
    getDepositFulfilled,
    isFetching: fetchingDepositFulfilled,
    isLoading: loadingDepositFulfilled,
    data: depositFulfilled,
  } = useGetDepositFulfilled(payload);

  // const {
  //   error: depositRecievedError,
  //   getDepositRecieved,
  //   isFetching: fetchingDepositRecieved,
  //   isLoading: loadingDepositRecieved,
  //   data: depositRecieved,
  // } = useGetDepositRecieved(payload);

  const isLoading =
    loadingDepositVol ||
    loadingDepositAvg ||
    loadingDepositReconData ||
    loadingDepositFulfilled;

  const error =
    depositVolError ||
    depositAvgError ||
    depositReconError ||
    depositFulfilledError;

  const isFetching =
    fetchingDepositVol ||
    fetchingDepositAvg ||
    fetchingDepositRecon ||
    fetchingDepositFulfilled;

  const onRetry = () => {
    if (depositVolError) {
      getDepositVolume();
    }

    if (depositAvgError) {
      getDepositAverage();
    }

    if (depositReconError) {
      getDepositReconcialiation();
    }

    if (depositFulfilledError) {
      getDepositFulfilled();
    }
  };

  return (
    <Page fullWidth title="Deposits">
      {isFetching && <NetworkLoader />}

      <LoaderContainer
        page
        loading={isLoading}
        error={!!error}
        errorMessage={error}
        onRetry={onRetry}
      >
        <Container>
          <div className="header">
            <DepositFilters
              queryParams={{ currency, startDate, endDate, time_interval }}
              updateQueryParams={updateQueryParams}
              chartData={volumes}
            />
          </div>

          {volumes && <DepositVolume data={volumes} />}

          <div className="row">
            {depositAvg && <AverageDeposit data={depositAvg} />}

            {depositReconData && (
              <DepositReconciliation data={depositReconData} />
            )}
          </div>

          {depositFulfilled && (
            <CustomerDeposits fulfilled={depositFulfilled} />
          )}
        </Container>
      </LoaderContainer>
    </Page>
  );
}

const Container = styled.div`
  ${tw`mt-[8px] flex flex-col gap-[24px]`};

  > .header {
    ${tw`flex gap-[16px]`};
  }

  > .row {
    ${tw`flex gap-[24px] w-full`};

    > * {
      ${tw`w-1/2`};
    }
  }
`;
