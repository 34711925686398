import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import tw, { styled } from "twin.macro";
import clsx from "clsx";

import { ReactComponent as Selected } from "app/assets/icons/check-outlined.svg";
import { ReactComponent as DropdownIcon } from "app/assets/icons/chevron-left.svg";
import SelectOption from "app/models/selectOption";
import DropdownButton from "./DropdownButton";

interface Props {
  name?: string;
  value: string;
  options: SelectOption[];
  onChange: (v: SelectOption) => void;
}
export default function DropdownSelect(props: Props) {
  const { value, onChange, options, name } = props;

  const selected = options.find((o) => o.value === value);

  return (
    // @ts-expect-error
    <Listbox name={name} value={selected} onChange={onChange}>
      <div tw="relative">
        {/* @ts-expect-error */}
        <Listbox.Button as={Fragment}>
          <DropdownButton type="button" small outline>
            {selected ? (
              <>
                {selected.icon && typeof selected.icon === "string" && (
                  <img src={selected.icon} alt={selected.label} />
                )}

                {selected.label}
              </>
            ) : (
              "Select option"
            )}

            <DropdownIcon className="dropdown" />
          </DropdownButton>
        </Listbox.Button>

        {/* @ts-expect-error */}
        <Transition
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          {/* @ts-expect-error */}
          <Listbox.Options as={Dropdown}>
            {options.map((option) => (
              // @ts-expect-error
              <Listbox.Option as={Fragment} key={option.value} value={option}>
                {({ selected, active }) => (
                  <Option
                    className={clsx({ active, "has-icon": !!option.icon })}
                  >
                    {option.icon && typeof option.icon === "string" && (
                      <img src={option.icon} alt={option.label} />
                    )}

                    <span>{option.label}</span>

                    {selected && <Selected className="selected-icon" />}
                  </Option>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

const Dropdown = styled.div`
  ${tw`absolute top-full mt-[13px] z-10`};

  ${tw`min-w-[181px] w-max max-h-[400px] overflow-x-visible overflow-y-auto rounded-[8px] py-[8px] border border-transparent bg-white shadow-[0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)]`};

  ${tw`dark:bg-greyScale90 dark:border-[rgba(255, 255, 255, 0.12)]`};
`;

const Option = styled.button`
  ${tw`w-full h-[40px] flex capitalize items-center gap-[16px] text-[1.6rem] pl-[20px] pr-[8px]`};

  .selected-icon {
    ${tw`w-[24px] h-[24px] flex-shrink-0 text-primary90 dark:text-prime`};

    path {
      ${tw`fill-current`};
    }
  }

  img {
    ${tw`w-[32px] h-[32px] rounded-full flex-shrink-0`};
  }

  span {
    ${tw`flex-grow text-left`};
  }

  &.has-icon {
    ${tw`h-[48px]`};
  }

  &.active {
    ${tw`bg-greyScale05`};

    ${tw`dark:bg-black`};
  }

  &:not(:last-of-type) {
    ${tw`mt-[4px]`};
  }
`;
