import { PropsWithChildren, ReactNode } from "react";
import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";

import Header from "app/styles/Header";
import SubHeader from "app/styles/SubHeader";
import { isString } from "app/utils/helpers";
import { ReactComponent as ChevronLeft } from "app/assets/icons/chevron-left.svg";

type PagebradCrumb = {
  label: string;
  onAction?: () => void;
  url?: string;
};

interface Props extends ContainerProps {
  title?: ReactNode;
  subTitle?: ReactNode;
  headerImageURL?: string;
  breadCrumbs?: PagebradCrumb;
}
export default function Page(props: PropsWithChildren<Props>) {
  const { children, fullWidth, title, subTitle, headerImageURL, breadCrumbs } =
    props;

  const showHeader = Boolean(
    title || subTitle || headerImageURL || breadCrumbs
  );

  const backContent = (
    <>
      <ChevronLeft /> {breadCrumbs?.label}
    </>
  );

  return (
    <Container fullWidth={fullWidth}>
      {showHeader && (
        <div className="header">
          {!!breadCrumbs && (
            <div className="breadcrumbs">
              {breadCrumbs.url ? (
                <Link to={breadCrumbs.url}>{backContent}</Link>
              ) : (
                <button onClick={breadCrumbs.onAction}>{backContent}</button>
              )}
            </div>
          )}

          {isString(title) ? <Header semiBold>{title}</Header> : title}

          {isString(subTitle) ? (
            <SubHeader medium>{subTitle}</SubHeader>
          ) : (
            subTitle
          )}

          {!!headerImageURL && (
            <img
              className="header-image"
              src={headerImageURL}
              alt={typeof title === "string" ? title : "Header Image"}
            />
          )}
        </div>
      )}

      <div className="body">{children}</div>
    </Container>
  );
}

interface ContainerProps {
  fullWidth?: boolean;
}

const Container = styled.section<ContainerProps>`
  width: ${(props) => (props.fullWidth ? "100%" : "600px")};

  ${tw`max-w-full my-0 mx-auto`};

  > .header {
    ${tw`mb-[16px]`};

    .breadcrumbs {
      ${tw`mb-[32px]`};

      a,
      button {
        ${tw`flex items-center text-[1.6rem] text-black60`};

        ${tw`dark:text-white60`};

        svg {
          ${tw`w-[20px] h-[20px] mr-[2px]`};

          path {
            ${tw`dark:fill-[white]`};
          }
        }
      }
    }

    img.header-image {
      ${tw`w-full h-[213px] mobile:h-[200px] object-contain mb-[8px] rounded-[8px]`};
    }

    > h4 {
      ${tw`text-black60 dark:text-white60 font-normal`};
    }
  }

  > .body {
    ${tw`w-full`};
  }
`;
