import { passwordConditions as passwordRegex } from "app/lib/regex";

const passwordConditions = [
  {
    name: "At least 8 characters",
    regex: passwordRegex.minLengthRegex,
  },
  {
    name: "At least one upper case character",
    regex: passwordRegex.minOneUpperCaseRegex,
  },
  {
    name: "At least one number",
    regex: passwordRegex.minOneNumberRegex,
  },
  {
    name: "At least one number or special character (!@#&$)",
    regex: passwordRegex.minOneSpecialRegex,
  },
];

export default passwordConditions;
