import tw, { styled } from "twin.macro";

import Text from "app/styles/Text";
import PinInput from "app/components/PinInput";
import { LoginResponse } from "app/api/auth/types";
import useConfirmLogin from "app/hooks/useConfirmLogin";

interface Props {
  onSuccess: (d: LoginResponse) => void;
  onGoBack: VoidFunction;
  loginResponse: LoginResponse;
}
export default function ConfirmationForm(props: Props) {
  const { onGoBack, loginResponse, onSuccess } = props;

  const { isLoading, confirmLogin, error } = useConfirmLogin();

  const onPinCompleted = (c: string) => {
    confirmLogin(
      {
        code: c,
        token: loginResponse.confirmation_token!,
      },
      {
        onSuccess(data) {
          onSuccess(data.data);
        },
      }
    );
  };

  return (
    <Container>
      <Text subdued>{loginResponse.message}</Text>

      <PinInput
        tw="mt-[48px]"
        onPinCompleted={onPinCompleted}
        disabled={isLoading}
        error={!!error}
        errorMessage={error}
      />

      <button css={[greenButtonStyles, tw`mt-[48px]`]}>Resend Code</button>

      <Text medium tw="mt-[48px]">
        Remembered password?
        <button css={greenButtonStyles} onClick={onGoBack}>
          Go Home
        </button>
      </Text>
    </Container>
  );
}

const Container = styled.div`
  ${tw`pt-[16px]`}
`;

const greenButtonStyles = tw`text-[1.6rem] text-primary70 font-semibold`;
