import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "app/components/Button";
import TextField from "app/components/TextField";
import Text from "app/styles/Text";
import { LoginPayload, LoginResponse } from "app/api/auth/types";
import useLogin from "../hooks/useLogin";

const schema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is not valid"),
  password: Yup.string().required("Password is required"),
});

interface Props {
  onSuccess: (d: LoginResponse) => void;
}
export default function LoginForm(props: Props) {
  const { onSuccess: onDone } = props;

  const { isLoading, login, error } = useLogin();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<LoginPayload>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((v) =>
    login(v, {
      onSuccess(data) {
        onDone(data.data);
      },
    })
  );

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <TextField
          label="Email address"
          placeholder="youremail@example.com"
          {...register("email")}
          error={!!errors.email}
          errorMessage={errors.email?.message}
          containerClassName="email-group"
        />

        <TextField
          label="Password"
          type="password"
          placeholder="Enter passsword"
          {...register("password")}
          error={!!errors.password}
          errorMessage={errors.password?.message || error}
        />

        <Button
          type="submit"
          primary
          tw="w-[120px] mt-[24px]"
          loading={isLoading}
        >
          Log in
        </Button>

        <Text medium tw="mt-[48px]">
          <Link to="/forgot-password" tw="text-primary70 font-semibold">
            Forgot password?
          </Link>
        </Text>
      </form>
    </Container>
  );
}

const Container = styled.div`
  ${tw`pt-[48px]`};

  > .header {
    ${tw`flex items-center justify-between`};
  }

  > form {
    .email-group {
      ${tw`mb-[24px]`};
    }
  }
`;
