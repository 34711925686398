import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { forgotPassword } from "app/api/auth";
import parseError from "app/lib/parseError";

export default function useForgotPassword() {
  const { isLoading, data, mutate, error } = useMutation(forgotPassword);

  return {
    isLoading,
    data: data?.data,
    forgotPassword: mutate,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
  };
}
