import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AuthPageContainer from "app/components/AuthPageContainer";
import SubHeader from "app/styles/SubHeader";
import LoginForm from "./components/LoginForm";
import ConfirmationForm from "./components/ConfirmationForm";
import { LoginResponse } from "app/api/auth/types";
import { useSetToken } from "app/providers/TokenProvider";
import { HOME_PATH, LOGIN_PATH } from "app/constants/variables";

type LoginStep = "login" | "confirm";

export default function Login() {
  const [activeStep, setActiveStep] = useState<LoginStep>("login");

  const [loginData, setLoginData] = useState<LoginResponse>();

  const setAppTokens = useSetToken();

  const navigate = useNavigate();
  const location = useLocation();

  const onLoginDone = (r: LoginResponse) => {
    const loginUser = () => {
      setAppTokens({
        access_token: r.access_token,
        refresh_token: r.refresh_token,
      });

      let redirectTo: string = location.state
        ? location.state.from || HOME_PATH
        : HOME_PATH;

      if (redirectTo === `/${LOGIN_PATH}`) {
        redirectTo = HOME_PATH;
      }

      navigate(redirectTo, { replace: true });
    };

    if (r.status === "success" && r.access_token) {
      loginUser();
      return;
    }

    if (r.status === "success" && r.confirmation_token) {
      setLoginData(r);
      setActiveStep("confirm");
    }
  };

  return (
    <AuthPageContainer>
      <SubHeader big>
        {activeStep !== "confirm" ? "Log in" : "Confirm Login"}
      </SubHeader>

      {activeStep === "login" ? <LoginForm onSuccess={onLoginDone} /> : null}

      {activeStep === "confirm" && loginData ? (
        <ConfirmationForm
          loginResponse={loginData}
          onSuccess={onLoginDone}
          onGoBack={() => {
            setActiveStep("login");
          }}
        />
      ) : null}
    </AuthPageContainer>
  );
}
