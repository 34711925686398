import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { getDepositReconcialiation } from "app/api/deposits";
import { GetDepositVolumePayload } from "app/api/deposits/types";
import parseError from "app/lib/parseError";

export default function useGetDepositReconciliation(
  params: GetDepositVolumePayload
) {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["deposit-reconciliation", params],
    () => getDepositReconcialiation(params)
  );

  return {
    data: data?.data.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getDepositReconcialiation: refetch,
    isFetching,
    isLoading,
  };
}
