import media from "app/styles/media";
import * as React from "react";
import tw, { css, styled } from "twin.macro";

import {
  DASHBOARD_BODY_PADDING_MOBILE_Y,
  DASHBOARD_BODY_PADDING_Y,
  DASHBOARD_HEADER_HEIGHT,
  DASHBOARD_MOBILE_NAV_HEIGHT,
} from "app/constants/variables";
import Loader from "./Loader";
import StatusView from "./StatusView";

interface Props extends LoaderContainerProps {
  loading?: boolean;
  error?: boolean;
  errorMessage?: string;
  onRetry?: () => void;
  retryText?: string;
  className?: string;
}
export default function LoaderContainer(props: React.PropsWithChildren<Props>) {
  const {
    children,
    error,
    loading,
    errorMessage = "Sorry, something went wrong.",
    onRetry,
    retryText,
    ...rest
  } = props;
  return (
    <Container {...rest}>
      {loading && (
        <div tw="p-[30px]">
          <Loader />
        </div>
      )}

      {error && (
        <StatusView
          status="error"
          description={errorMessage}
          primaryAction={{ label: retryText ?? "Retry", onAction: onRetry }}
          tw="max-w-full"
        />
      )}

      {!loading && !error && <div tw="w-full h-full flex-grow">{children}</div>}
    </Container>
  );
}

interface LoaderContainerProps {
  screen?: boolean;
  page?: boolean;
}

const Container = styled.div<LoaderContainerProps>`
  ${tw`w-full h-full flex items-center justify-center mobile:px-[16px]`};

  ${(props) =>
    props.screen &&
    css`
      ${tw`h-screen mobile:px-0`};
    `}

  ${(props) =>
    props.page &&
    css`
      height: calc(
        100vh - ${DASHBOARD_HEADER_HEIGHT}px - ${DASHBOARD_BODY_PADDING_Y}px -
          ${DASHBOARD_BODY_PADDING_Y}px
      );
      max-height: 80vh;

      ${media.mobile} {
        height: calc(
          100vh - ${DASHBOARD_HEADER_HEIGHT}px -
            ${DASHBOARD_MOBILE_NAV_HEIGHT}px -
            ${DASHBOARD_BODY_PADDING_MOBILE_Y}px -
            ${DASHBOARD_BODY_PADDING_MOBILE_Y}px
        );
      }
    `}
`;
