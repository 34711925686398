import { NavLink, useLocation, useNavigate } from "react-router-dom";
import tw, { styled, theme } from "twin.macro";

import { useAppTheme } from "app/providers/AppThemeProvider";
import appLinks from "app/constants/appLinks";
import { ReactComponent as Logout } from "app/assets/icons/logout.svg";
import SubHeader from "app/styles/SubHeader";
import { SIGN_OUT_PATH } from "app/constants/variables";

export default function DashboardSideNav() {
  const theme = useAppTheme();

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Container>
      <NavigationSection>
        {appLinks.map(({ children, label, icon: Icon, url }) => {
          const hasSubnav = Boolean(children && children.length);
          const openSubnav = (children ?? [])
            .map((r) => r.url)
            .includes(location.pathname);

          return (
            <li key={label}>
              <Link
                to={url}
                end
                className={openSubnav ? "subnav-active" : undefined}
              >
                <Icon className={theme} /> {label}
              </Link>

              {hasSubnav && openSubnav && (
                <SubNav className="subnav">
                  {children?.map((subLink) => (
                    <Link key={subLink.label} to={subLink.url}>
                      {subLink.label}
                    </Link>
                  ))}
                </SubNav>
              )}
            </li>
          );
        })}

        <div className="bottom" onClick={(e) => navigate(`/${SIGN_OUT_PATH}`)}>
          <Logout />
          <SubHeader tw="pl-3">Logout</SubHeader>
        </div>
      </NavigationSection>
    </Container>
  );
}

const Container = styled.nav`
  ${tw`w-full h-page py-[24px] px-[32px] flex border-r`};

  ${tw`bg-white `};

  ${tw`dark:bg-black dark:border-r-white12`};
`;

const NavigationSection = styled.ul`
  position: relative;
  &,
  li {
    ${tw`w-full`};
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    cursor: pointer;

    path {
      ${tw`dark:fill-white`};
    }
  }
`;

const Link = styled(NavLink)`
  ${tw`w-full h-[46px] rounded-[8px] px-[16px] flex items-center text-[1.6rem] text-black32 font-semibold mb-[8px] last-of-type:mb-0`};

  ${tw`dark:text-white32`};

  svg {
    ${tw`w-[20px] h-[20px] mr-[10px]`};

    &.dark {
      path {
        fill: ${theme<string>`colors.white32`};
        fill-opacity: 1;
      }
    }
  }

  &.active,
  &.subnav-active {
    ${tw`bg-greyScale10 text-black`};

    ${tw`dark:bg-transparent dark:text-white95`};

    svg {
      path {
        fill: black;
        fill-opacity: 1;
      }

      &.dark {
        path {
          fill: white;
          fill-opacity: 1;
        }
      }
    }
  }

  &.subnav-active {
    ${tw`bg-transparent`};
  }
`;

const SubNav = styled.div`
  ${tw`pl-[17px]`};

  ${Link} {
    ${tw`mb-0 border-l border-l-black12`};
    border-radius: 0px 8px 8px 0px;

    &.active {
      ${tw`border-l-black95`};
    }
  }
`;
