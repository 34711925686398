import { ReactNode } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import tw, { styled } from "twin.macro";

import {
  DASHBOARD_BODY_PADDING_MOBILE_X,
  DASHBOARD_BODY_PADDING_MOBILE_Y,
  DASHBOARD_BODY_PADDING_X,
  DASHBOARD_BODY_PADDING_Y,
  DASHBOARD_HEADER_HEIGHT,
  DASHBOARD_MOBILE_NAV_HEIGHT,
  DASHBOARD_SIDEBAR_WIDTH,
} from "app/constants/variables";
import LoaderContainer from "./LoaderContainer";
import useAuth from "app/hooks/useAuth";
import useGetProfile from "app/hooks/useGetProfile";
import NetworkLoader from "./NetworkLoader";
import { useProfile } from "app/providers/ProfileProvider";
import media from "app/styles/media";
import useGetCurrencies from "app/hooks/useGetCurrencies";
interface Props {
  header: ReactNode;
  sidenav: ReactNode;
  mobilenav: ReactNode;
}

export default function DashboardLayout(props: Props) {
  const { header, sidenav, mobilenav } = props;

  const isLoggedIn = useAuth();
  const location = useLocation();

  const {
    isLoading: loadingProfile,
    isFetching: fetchingProfile,
    error: profileError,
    refetch: getProfile,
  } = useGetProfile(isLoggedIn);

  const {
    isLoading: loadingCurrencies,
    isFetching: fetchingCurrencies,
    error: currenciesError,
    refetch: getCurrencies,
  } = useGetCurrencies(isLoggedIn);

  const isLoading = loadingProfile || loadingCurrencies;

  const isFetching = fetchingProfile || fetchingCurrencies;

  const error = profileError || currenciesError;

  const refetch = () => {
    if (profileError) {
      getProfile();
    }

    if (currenciesError) {
      getCurrencies();
    }
  };

  const profile = useProfile();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }

  return (
    <>
      {isFetching && !isLoading ? <NetworkLoader page={false} /> : null}

      <LoaderContainer
        screen
        loading={isLoading && !profile}
        error={!!error}
        onRetry={refetch}
      >
        <Container>
          <div className="header">{header}</div>

          <section className="body">
            <aside className="sidebar">{sidenav}</aside>

            <main className="content">
              <Outlet />
            </main>
          </section>

          <footer>{mobilenav}</footer>
        </Container>
      </LoaderContainer>
    </>
  );
}

const Container = styled.section`
  ${tw`w-full h-screen flex flex-col`};

  ${tw`bg-greyScale05`};

  ${tw`dark:bg-black`};

  > .header {
    height: ${DASHBOARD_HEADER_HEIGHT}px;
    position: sticky;
    top: 0;
  }

  > .body {
    ${tw`flex-grow w-full flex`};
    height: calc(100vh - ${DASHBOARD_HEADER_HEIGHT}px);

    ${media.mobile} {
      height: calc(
        100vh - ${DASHBOARD_HEADER_HEIGHT}px - ${DASHBOARD_MOBILE_NAV_HEIGHT}px
      );
    }
  }

  > .body > .sidebar {
    width: ${DASHBOARD_SIDEBAR_WIDTH}px;
    height: 100%;
    position: sticky;
    top: ${DASHBOARD_HEADER_HEIGHT}px;

    ${tw`mobile:hidden`};
  }

  > .body > .content {
    ${tw`w-full overflow-x-hidden overflow-y-auto`};

    padding-left: ${DASHBOARD_BODY_PADDING_X}px;
    padding-right: ${DASHBOARD_BODY_PADDING_X}px;

    padding-top: ${DASHBOARD_BODY_PADDING_Y}px;
    padding-bottom: ${DASHBOARD_BODY_PADDING_Y}px;

    ${media.mobile} {
      padding-left: ${DASHBOARD_BODY_PADDING_MOBILE_X}px;
      padding-right: ${DASHBOARD_BODY_PADDING_MOBILE_X}px;

      padding-top: ${DASHBOARD_BODY_PADDING_MOBILE_Y}px;
      padding-bottom: ${DASHBOARD_BODY_PADDING_MOBILE_Y}px;
    }
  }

  > footer {
    flex-shrink: 0;
    height: ${DASHBOARD_MOBILE_NAV_HEIGHT}px;
    position: sticky;
    bottom: 0;

    ${tw`w-full hidden mobile:block`};
  }
`;
