import tw, { styled } from "twin.macro";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import Reaptcha from "reaptcha";

import Button from "app/components/Button";
import TextField from "app/components/TextField";
// import { RECAPTCHA_SITE_KEY } from "app/constants/variables";
import SubHeader from "app/styles/SubHeader";
import useForgotPassword from "../hooks/useForgotPassword";
import {
  ForgotPasswordPayload,
  ForgotPasswordResponse,
} from "app/api/auth/types";
// import Text from "app/styles/Text";

const schema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is not valid"),
  // token: Yup.string().required("Captcha is required"),
});

interface Props {
  onSuccess: (d: ForgotPasswordResponse) => void;
}
export default function EmailForm(props: Props) {
  const { onSuccess } = props;

  const { isLoading, forgotPassword, error } = useForgotPassword();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<ForgotPasswordPayload>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((v) =>
    forgotPassword(v, {
      onSettled(data) {
        if (!data) return;
        onSuccess(data.data);
      },
    })
  );

  const errorMessage = errors.email?.message || error;

  return (
    <>
      <SubHeader big>Reset password</SubHeader>

      <Container>
        <form onSubmit={onSubmit}>
          <TextField
            autoFocus
            label="Email address"
            placeholder="youremail@example.com"
            {...register("email")}
            error={!!errorMessage}
            errorMessage={errorMessage}
          />

          {/* <Reaptcha
            tw="mt-[24px]"
            sitekey={RECAPTCHA_SITE_KEY}
            onVerify={(token) => setValue("token", token)}
          />
          {errors.token ? (
            <Text small tw="mt-[8px] text-error70 dark:text-error50">
              {errors.token.message}
            </Text>
          ) : null} */}

          <Button
            type="submit"
            primary
            tw="w-[120px] mt-[24px]"
            loading={isLoading}
          >
            Next
          </Button>
        </form>
      </Container>
    </>
  );
}

const Container = styled.div`
  ${tw`mt-[16px]`}

  > .header {
    ${tw`mt-[48px] flex items-center justify-between`};
  }

  > form {
    ${tw`pt-[48px]`}
  }
`;
