import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { getAverageDeposit } from "app/api/deposits";
import { GetDepositVolumePayload } from "app/api/deposits/types";
import parseError from "app/lib/parseError";

export default function useGetAverageDeposit(params: GetDepositVolumePayload) {
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["deposit-average", params],
    () => getAverageDeposit(params)
  );

  return {
    data: data?.data.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getDepositAverage: refetch,
    isFetching,
    isLoading,
  };
}
