import DateRangePicker from "app/components/DateRangePicker";
import DropdownSelect from "app/components/DropdownSelect";
import timeIntervals from "app/constants/timeIntervals";
import { DepositQueryParams } from "../types";
import { GenericChartData } from "app/api/types";
import SelectOption from "app/models/selectOption";
import { useCurrencies } from "app/providers/CurrenciesProvider";
import { getCurrencySelectOptions } from "app/utils/currency";

interface Props {
  queryParams: DepositQueryParams;
  updateQueryParams: (q: Partial<DepositQueryParams>) => void;
  chartData?: GenericChartData;
}
export default function DepositFilters(props: Props) {
  const { queryParams, updateQueryParams, chartData } = props;

  const currencies = getCurrencySelectOptions(useCurrencies() ?? []);

  return (
    <>
      <DropdownSelect
        value={queryParams.currency}
        options={currencies}
        onChange={(o) => updateQueryParams({ currency: o.value })}
      />

      <DateRangePicker
        startDate={queryParams.startDate}
        endDate={queryParams.endDate}
        onChange={(d) =>
          updateQueryParams({ startDate: d.start, endDate: d.end })
        }
      />

      <DropdownSelect
        value={chartData ? chartData.unit : queryParams.time_interval}
        options={
          chartData ? getTimeIntervals(chartData.valid_units) : timeIntervals
        }
        onChange={(o) => updateQueryParams({ time_interval: o.value })}
      />
    </>
  );
}

function getTimeIntervals(units: string[]) {
  const intervals = units
    .map((u) => timeIntervals.find((t) => t.value === u))
    .filter(Boolean);

  return intervals as SelectOption[];
}
