import { useState } from "react";
import tw, { styled } from "twin.macro";

import Card from "app/components/Card";
import LineChart from "app/components/Charts/LineChart";
import DateRangePicker from "app/components/DateRangePicker";
import DropdownSelect from "app/components/DropdownSelect";
import Page from "app/components/Page";
import { COIN_IMG_BASE_URL } from "app/constants/variables";

const chartData = [
  {
    label: "Label",
    // borderColor: "#B28300",
    data: [
      {
        x: new Date("02-10-2017 GMT"),
        y: 34,
      },
      {
        x: new Date("02-11-2017 GMT"),
        y: 43,
      },
      //   {
      //     x: new Date("02-12-2017 GMT"),
      //     y: 31,
      //   },
      //   {
      //     x: new Date("02-13-2017 GMT"),
      //     y: 43,
      //   },
      {
        x: new Date("02-14-2017 GMT"),
        y: 33,
      },
      {
        x: new Date("02-15-2017 GMT"),
        y: 52,
      },
    ],
  },
  {
    label: "Label 2",
    borderColor: "rgb(255,0,0)",
    data: [
      {
        x: new Date("02-10-2017 GMT"),
        y: 40,
      },
      {
        x: new Date("02-11-2017 GMT"),
        y: 49,
      },
      {
        x: new Date("02-12-2017 GMT"),
        y: 37,
      },
      {
        x: new Date("02-13-2017 GMT"),
        y: 49,
      },
      {
        x: new Date("02-14-2017 GMT"),
        y: 39,
      },
      {
        x: new Date("02-15-2017 GMT"),
        y: 52,
      },
    ],
  },
];

export default function Demo() {
  const [date, setDate] = useState({
    start: new Date().toDateString(),
    end: new Date().toDateString(),
  });

  return (
    <Page fullWidth title="Deposits">
      <Container>
        <div className="header">
          <DropdownSelect
            value="BTC"
            options={[
              {
                label: "BTC",
                value: "BTC",
                icon: `${COIN_IMG_BASE_URL}/BTC.svg`,
              },
            ]}
            onChange={console.log}
          />

          <DateRangePicker
            startDate={date.start}
            endDate={date.end}
            onChange={setDate}
          />

          <DropdownSelect
            value="monthly"
            options={[
              {
                label: "Monthly",
                value: "monthly",
              },
            ]}
            onChange={console.log}
          />
        </div>

        <Card blackOnDark tw="p-[24px]">
          <LineChart datasets={chartData} currency="ETH" />
        </Card>
      </Container>
    </Page>
  );
}

const Container = styled.div`
  > .header {
    ${tw`py-[24px] flex gap-[16px]`};
  }
`;
