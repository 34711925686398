import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { formatMoneyNoCurrency } from "app/lib/money";
// import { BadgeProps } from "@shopify/polaris";

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

/**
 * Converts the first letter of the string to uppercase
 */
export const capitalizeStr = (str: string) => {
  if (typeof str !== "string") return "";

  return str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase();
};

/**
 * Get file size of a File object in kilobytes
 */
export const getFileSizeInKB = (size: number) => parseInt(String(size / 1024));

/**
 * Decode string
 */
export const encodeString = (str: string) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );

/**
 * Decode string
 */
export const decodeString = (str: string) =>
  decodeURIComponent(
    Array.prototype.map
      .call(
        atob(str),
        (c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      )
      .join("")
  );

/**
 * Get english possessive nouns
 */
export const getPossessiveNoun = (noun: string) => {
  if (noun.endsWith("s")) {
    return `${noun}'`;
  }
  return `${noun}'s`;
};

/**
 * Get scale for text
 */
export function scaleBetween(
  unscaledNum: number,
  minAllowed: number,
  maxAllowed: number,
  min: number,
  max: number
) {
  return (
    ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) + minAllowed
  );
}

/**
 * Get bank slug string from bank name string
 */
export function getBankSlug(name: string) {
  return name.split(" ").join("-").toLowerCase();
}

interface Obj {
  [key: string]: any;
}

/**
 * Create object from an array of objects
 */
export function mapKeys(items: Obj[], key: string, exclude: string[] = []) {
  return items.reduce((obj: Obj, currentObj: Obj) => {
    const newObject: any = {
      ...currentObj,
      ...exclude.map((k) => ({ [k]: undefined })),
    };

    obj[currentObj[key]] = newObject;
    return obj;
  }, {});
}

/**
 * Create map from an array of objects
 */
export function createMap(items: Obj[], key: string, mappedKey?: string) {
  const map = new Map();
  items.forEach((item) => {
    map.set(item[key], mappedKey ? item[mappedKey] : item);
  });
  return map;
}

/**
 * Format  string to a `{MMM} {DD}, {YYYY}` format
 * @param dateStr
 * @param format (optional defaults to MMM DD, YYYY)
 */
export function formatDateStr(
  dateStr: string | number | Date,
  format?: string
) {
  return dayjs(dayjs(dateStr)).format(format || "MMM DD, YYYY");
}

/**
 * Pasre a  string
 * @param dateStr
 * @param format
 * @returns dayjs.Dayjs
 */
export function parseDate(
  dateStr: string,
  format?: dayjs.OptionType | undefined
) {
  return dayjs(dateStr, format);
}

export function parseDOB(dateStr: string) {
  return parseDate(dateStr, "DD/MM/YYYY").toDate();
}

/**
 * Get time string form a date string
 */
export function getDateTime(
  dateStr: string,
  timeFormat: "12h" | "24h" = "24h"
) {
  const format = timeFormat === "12h" ? "hh:mma" : "HH:mm";
  return dayjs(new Date(dateStr)).format(format);
}

/**
 * get relative time from now
 */
export function getRelativeTime(time: Date) {
  return dayjs(time).fromNow();
}

/**
 * Format the transaction amount which comes in the format of '{CURRENCY} {AMOUNT}`
 */
export function formatTransactionAmountStr(
  str: string,
  type: "debit" | "credit" = "credit"
) {
  const [currency, amount] = str.split(" ");
  const formattedAmount = formatMoneyNoCurrency(Number(amount), undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 8,
  });
  const sign = type === "credit" ? "" : "-";

  return `${sign}${formattedAmount} ${currency}`;
}

export function isString(val: unknown) {
  return typeof val === "string";
}
