import tw, { styled } from "twin.macro";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "app/components/Button";
import TextField from "app/components/TextField";
import { LOGIN_PATH } from "app/constants/variables";
import SubHeader from "app/styles/SubHeader";
import {
  ForgotPasswordResponse,
  ResetPasswordPayload,
} from "app/api/auth/types";
import Text from "app/styles/Text";
import passwordConditions from "app/constants/passwordConditions";
import PasswordCondition from "app/components/PasswordCondition";
import useResetPassword from "../hooks/useResetPassword";
import PinInput from "app/components/PinInput";
import useCountdownTimer from "app/hooks/useCountdownTimer";
import Loader from "app/components/Loader";
import useResendAuthCode from "app/hooks/useResendAuthCode";
import { Link } from "react-router-dom";

const schema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .test("strong-password", "Password not strong enough", (password) => {
      if (!password) return false;

      for (const condition of passwordConditions) {
        if (!condition.regex.test(password)) {
          return false;
        }
      }
      return true;
    }),
  confirmation_code: Yup.string().required("Code is required"),
});

interface Props {
  onSuccess: () => void;
  forgotPasswordData: ForgotPasswordResponse;
}
export default function PasswordForm(props: Props) {
  const { onSuccess, forgotPasswordData } = props;

  const {
    restart: restartTimer,
    time: { seconds },
  } = useCountdownTimer(60000);

  const { resendAuthCode, isLoading: isResendingCode } = useResendAuthCode();
  const onResendCode = () => {
    resendAuthCode(forgotPasswordData.token, {
      onSuccess() {
        restartTimer();
      },
    });
  };

  const { isLoading, resetPassword, error } = useResetPassword();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<ResetPasswordPayload>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((v) =>
    resetPassword(
      { ...v, forgotPasswordToken: forgotPasswordData.token },
      {
        onSettled(data) {
          if (!data) return;
          onSuccess();
        },
      }
    )
  );

  return (
    <>
      <SubHeader big>Set new password</SubHeader>

      <Container>
        <Text medium subdued>
          {forgotPasswordData.message}
        </Text>

        <form onSubmit={onSubmit}>
          <PinInput
            onPinCompleted={(c) => setValue("confirmation_code", c)}
            disabled={isLoading}
            error={!!errors.confirmation_code}
            errorMessage={errors.confirmation_code?.message}
          />

          <Text medium tw="mt-[24px] mb-[48px] flex items-center">
            Did not recieve code?{" "}
            <button
              type="button"
              css={greenButtonStyles}
              disabled={seconds !== 0}
              onClick={onResendCode}
            >
              {isResendingCode ? (
                <Loader size="20" />
              ) : (
                <>Resend code {seconds !== 0 && `in ${seconds}`}</>
              )}
            </button>
          </Text>

          <Controller
            name="password"
            control={control}
            render={({ field: { value, ...field } }) => (
              <>
                <TextField
                  label="Password"
                  placeholder="Enter password"
                  type="password"
                  value={value}
                  {...field}
                  error={!!errors.password}
                  errorMessage={errors.password?.message || error}
                />

                {/* password conditions */}
                <div tw="mt-[24px]">
                  {passwordConditions.map((condition) => {
                    const matches = Boolean(
                      value && value.length && condition.regex.test(value)
                    );

                    return (
                      <PasswordCondition
                        key={condition.name}
                        matches={matches}
                        condition={condition}
                      />
                    );
                  })}
                </div>
              </>
            )}
          />

          <Button type="submit" primary tw="mt-[24px]" loading={isLoading}>
            Reset password
          </Button>

          <Text medium tw="mt-[48px]">
            Remembered password?{" "}
            <Link to={`/${LOGIN_PATH}`} css={greenButtonStyles}>
              Go Home
            </Link>
          </Text>
        </form>
      </Container>
    </>
  );
}

const Container = styled.div`
  ${tw`mt-[16px] pb-[16px]`};

  > .header {
    ${tw`mt-[48px] flex items-center justify-between`};
  }

  > form {
    ${tw`pt-[48px]`}
  }
`;

const greenButtonStyles = tw`text-[1.6rem] text-primary70 font-semibold ml-[4px]`;
