import { useRef, useState } from "react";
import clsx from "clsx";
import tw, { styled } from "twin.macro";

import Text from "app/styles/Text";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";

interface CodeObject {
  [key: string]: { [key: string]: string };
}

type CodeInputRef = React.MutableRefObject<HTMLInputElement | null>;

const initialCodObject: CodeObject = {
  first: { 0: "", 1: "", 2: "" },
  second: { 0: "", 1: "", 2: "" },
};

interface Props {
  onPinCompleted: (code: string) => void;
  disabled?: boolean;
  className?: string;
  error?: boolean;
  errorMessage?: string;
}
export default function PinInput(props: Props) {
  const { disabled, className, error, errorMessage, onPinCompleted } = props;

  const [codeObject, setCodeObject] = useState(initialCodObject);

  const inputRefs: CodeInputRef[] = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),

    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const onInputChange =
    (section: string, key: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (codeObject[section][key]) return;

      const value = e.target.value.trim().split(" ").join("");
      if (isNaN(parseInt(value, 10)) && value.length < 1) return;

      if (section === "first" && key === "0" && value.length >= 6) {
        // handle paste
        setCodeObject({
          first: { 0: value.charAt(0), 1: value.charAt(1), 2: value.charAt(2) },
          second: {
            0: value.charAt(3),
            1: value.charAt(4),
            2: value.charAt(5),
          },
        });
      } else {
        setCodeObject((_codeObject) => ({
          ..._codeObject,
          [section]: { ..._codeObject[section], [key]: value.charAt(0) },
        }));
      }
    };

  const gotoNextInput = (index: number) => {
    const nextInput = inputRefs[index + 1];
    if (nextInput) {
      nextInput.current?.focus();
    }
  };
  const gotoPrevInput = (index: number) => {
    const prevInput = inputRefs[index - 1];
    if (prevInput) {
      prevInput.current?.focus();
    }
  };

  const onKeyUp =
    (section: string, key: string, index: number) =>
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation();
      if (
        section === "first" &&
        key === "0" &&
        getCode(codeObject).length >= 6
      ) {
        inputRefs[inputRefs.length - 1]?.current?.focus();
        return;
      }

      if (codeObject[section][key].length > 0) {
        if (e.key === "Backspace") {
          setCodeObject((_codeObject) => ({
            ..._codeObject,
            [section]: { ..._codeObject[section], [key]: "" },
          }));
        } else {
          gotoNextInput(index);
        }
      } else {
        const keyCode = e.which || e.keyCode;

        if (
          e.key === "Enter" ||
          e.ctrlKey ||
          e.metaKey ||
          e.altKey ||
          e.shiftKey ||
          (keyCode >= 65 && keyCode <= 90) ||
          (keyCode > 185 && keyCode < 193) ||
          (keyCode > 218 && keyCode < 223)
        )
          return;

        if (e.key !== "Backspace") gotoNextInput(index);
        else gotoPrevInput(index);
      }
    };

  useUpdatedEffect(() => {
    const code = getCode(codeObject);
    if (code.length === 6) {
      onPinCompleted(code);
    }
  }, [codeObject]);

  return (
    <Container className={className}>
      <div className="row">
        <div className={clsx("section", { error })}>
          {Object.keys(codeObject.first).map((key, index) => (
            <input
              autoComplete="off"
              type="text"
              inputMode="numeric"
              key={`${key}-${index}}`}
              autoFocus={key === "0"}
              value={codeObject.first[key]}
              onChange={onInputChange("first", key)}
              onKeyUp={onKeyUp("first", key, index)}
              ref={inputRefs[index]}
              disabled={disabled}
            />
          ))}
        </div>

        <div className="divider" />

        <div className={clsx("section", { error })}>
          {Object.keys(codeObject.second).map((key, index) => (
            <input
              autoComplete="off"
              type="text"
              inputMode="numeric"
              key={`${key}-${index}}`}
              value={codeObject.second[key]}
              onChange={onInputChange("second", key)}
              onKeyUp={onKeyUp("second", key, index + 3)}
              ref={inputRefs[index + 3]}
              disabled={disabled}
            />
          ))}
        </div>
      </div>

      {!!errorMessage ? (
        <Text className="error-message">{errorMessage}</Text>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  > p.error-message {
    ${tw`text-error70 dark:text-error50 mt-[8px]`};
  }

  > .row {
    ${tw`flex items-center`};
  }

  .divider {
    ${tw`w-[16px] h-[2px] rounded-[6px] bg-black/[.24] mx-[8px]`};
  }

  .section {
    input {
      ${tw`bg-transparent w-[48px] h-[48px] px-[10px] text-base text-center outline-none border-[2px] rounded-[4px] border-greyScale30 focus:border-primary70`};

      ${tw`dark:border-greyScale70 dark:focus:border-primary70`};

      &:nth-of-type(1) {
        ${tw`rounded-r-none`};
      }

      &:nth-of-type(even) {
        ${tw`border-l-0 border-r-0 rounded-none`};
      }

      &:nth-of-type(3) {
        ${tw`rounded-l-none`};
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    &.error {
      input {
        ${tw`border-error70 dark:border-error50`};
      }
    }
  }
`;

function getCode(codeObject: CodeObject) {
  return `${Object.values(codeObject.first).join("")}${Object.values(
    codeObject.second
  ).join("")}`;
}
